import { useState, useEffect, useRef, useCallback } from 'react';
import { getCachedImageUrl, setCachedImageUrl } from './imageUrlCache';
import { getImageUrl } from '../modules/surveydata';

export function useImageUrlCache(imageIds, surveyId, dispatch) {
    const [imageUrls, setImageUrls] = useState({});
    const [loading, setLoading] = useState(true);
    const fetchedImageIds = useRef(new Set());

    const fetchAllImageUrls = useCallback(async () => {
        try {
            for (const imageId of imageIds) {
                // Check session storage cache first
                const cachedUrl = getCachedImageUrl(surveyId, imageId);
                if (cachedUrl) {
                    setImageUrls((prev) => ({ ...prev, [imageId]: cachedUrl }));
                    continue;
                }

                // Fetch if not already in memory cache
                if (!fetchedImageIds.current.has(imageId)) {
                    fetchedImageIds.current.add(imageId);
                    const response = await getImageUrl(imageId)(dispatch);
                    if (response) {
                        setImageUrls((prev) => ({ ...prev, [imageId]: response }));
                        setCachedImageUrl(surveyId, imageId, response);
                    }
                }
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching image URLs:', error);
        }
    }, [imageIds, surveyId, dispatch]);

    useEffect(() => {
        fetchAllImageUrls();
    }, [fetchAllImageUrls]);

    return { imageUrls, loading };
}

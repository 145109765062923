import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppBar, Box, Grid, Tabs, Tab } from '@material-ui/core';
import { Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { getOpenSurveysForUserPortfolio } from '../modules/surveydata';
import { useTranslation } from 'react-i18next';
import { orderBy } from 'lodash';
import dayjs from 'dayjs';
import TabPanel from '../common/tabpanel';

const useStyles = makeStyles((theme) => ({
    surveyRow: {
        border: '1px solid #ccc',
        margin: '5px 0',
        padding: '10px',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    tabPanel: {
        width: '99%',
    },
}));
export default function Surveys(props) {
    const dispatch = useDispatch();
    const features = useSelector((state) => state.settingsdata.features);
    const surveysInUse = features != null ? features.surveysInUse : false;
    const [surveys, setSurveys] = useState([]);
    const [openSurveys, setOpenSurveys] = useState([]);
    const [closedSurveys, setClosedSurveys] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const classes = useStyles();
    const { t } = useTranslation();

    useEffect(() => {
        async function getSurveys() {
            var resp = await getOpenSurveysForUserPortfolio()(dispatch);
            if (resp.length > 0) {
                const sortedSurveys = orderBy(resp, ['updateDate'], ['desc']);
                setSurveys(sortedSurveys);
                setClosedSurveys(sortedSurveys.filter((s) => s.locationId !== null && s.locationId > 0));
                setOpenSurveys(sortedSurveys.filter((s) => s.locationId === null || s.locationId <= 0));
            }
        }
        if (surveysInUse) {
            getSurveys();
        }
        if (window.location.hash === '#completed') {
            setActiveTab(1);
        }
    }, [surveysInUse, dispatch]);

    const openSurvey = (id) => {
        props.history.push('/survey/' + id);
    };

    const onTabChange = (event, selectedTab) => {
        setActiveTab(selectedTab);
        props.history.push(selectedTab === 0 ? '/surveys' : '/surveys#completed');
    };

    if (surveys.length === 0) {
        return (
            <Grid container>
                <Grid item xs={12}>
                    {t('survey.noSurveys')}
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container>
            <AppBar position="static" color="default">
                <Box display="flex" justifyContent="center" width="100%">
                    <Tabs
                        value={activeTab}
                        onChange={onTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto">
                        <Tab
                            value={0}
                            label={`${t('survey.uncompletedSurveys')} (${openSurveys.length})`}
                            name="tab-open-sruveys"
                        />
                        <Tab
                            value={1}
                            label={`${t('survey.completedSurveys')} (${closedSurveys.length})`}
                            name="tab-closed-surveys"
                        />
                    </Tabs>
                </Box>
            </AppBar>
            <TabPanel value={activeTab} index={0} className={classes.tabPanel}>
                <Grid container>
                    {openSurveys.map((survey) => (
                        <Grid item xs={12} key={survey.id}>
                            <div onClick={() => openSurvey(survey.id)} className={classes.surveyRow}>
                                <Grid container alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <h3>{survey.name}</h3>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" color="textSecondary">
                                            {t('survey.modifiedAt', {
                                                timeStamp: dayjs
                                                    .utc(survey.updateDate)
                                                    .local()
                                                    .format('DD.MM.YYYY HH:mm'),
                                            })}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </TabPanel>
            <TabPanel value={activeTab} index={1} className={classes.tabPanel}>
                <Grid container>
                    {closedSurveys.map((survey) => (
                        <Grid item xs={12} key={survey.id}>
                            <div onClick={() => openSurvey(survey.id)} className={classes.surveyRow}>
                                <Grid container alignItems="center" justifyContent="space-between">
                                    <Grid item>
                                        <h3>{survey.name}</h3>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" color="textSecondary">
                                            {t('survey.modifiedAt', {
                                                timeStamp: dayjs
                                                    .utc(survey.updateDate)
                                                    .local()
                                                    .format('DD.MM.YYYY HH:mm'),
                                            })}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </TabPanel>
        </Grid>
    );
}

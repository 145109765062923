import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    logo: {
        height: '40px',
        width: 'auto',
    },
};

class VadoLogo extends React.Component {
    render() {
        return <img className={this.props.classes.logo} src="/Vado-logo-blue-RGB.png" alt="VADO Logo" />;
    }
}

export default withStyles(styles)(VadoLogo);

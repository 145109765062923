import React from 'react';
import PropTypes from 'prop-types';
import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export default function VideoDialog({ videoUrl, onClose }) {
    return (
        <Dialog open={Boolean(videoUrl)} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1}>{'Video'}</Box>
                    <Box>
                        <IconButton data-testid="close-button" onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>

            <DialogContent>
                <video data-testid="video-player" controls width="100%">
                    <source data-testid="video-source" src={videoUrl} type="video/mp4" />
                    Your browser does not support the video.
                </video>
            </DialogContent>
        </Dialog>
    );
}

VideoDialog.propTypes = {
    videoUrl: PropTypes.string, // The URL of the video (can be null or undefined when closed)
    onClose: PropTypes.func.isRequired, // Function to handle closing the dialog
};

import Ajax from './ajax';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { LOGGED_OFF } from './authentication';

export const SURVEY_SAVED = 'surveydata/SURVEYSAVED';
export const DOWNLOADING_PDF = 'surveydata/DOWNLOADING_PDF';
export const DOWNLOADING_PDF_DONE = 'surveydata/DOWNLOADING_PDF_DONE';

const initialState = {
    surveys: null,
    isFetchingSurveyData: false,
    downloadingPdf: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SURVEY_SAVED:
            return {
                ...state,
                surveys: null,
            };
        case DOWNLOADING_PDF:
            return {
                ...state,
                downloadingPdf: true,
            };
        case DOWNLOADING_PDF_DONE: {
            return {
                ...state,
                downloadingPdf: false,
            };
        }
        case LOGGED_OFF:
            return initialState;
        default:
            return state;
    }
};

export const downloadingPdf = () => {
    return (dispatch) => {
        dispatch({ type: DOWNLOADING_PDF });
    };
};

export const downloadingPdfDone = () => {
    return (dispatch) => {
        dispatch({ type: DOWNLOADING_PDF_DONE });
    };
};

export const getOpenSurveysForUserPortfolio = () => {
    return async (dispatch) => {
        try {
            const resp = await Ajax.get('api/survey');
            return resp.data;
        } catch (err) {
            return null;
        }
    };
};

export const getSurvey = (surveyId) => {
    return async (dispatch) => {
        try {
            const resp = await Ajax.get(`api/survey/${surveyId}`);
            return resp.data;
        } catch (err) {
            console.log(err);
        }
    };
};

export const getSurveyPdf = (surveyId, fileName = 'document.pdf') => {
    return async (dispatch) => {
        try {
            await Ajax.get('api/survey/pdf/' + surveyId, {});
            toast.info(i18next.t('createPdf.pdfCreateInProgress'), { autoClose: 10000, hideProgressBar: true });
        } catch (err) {
            console.log(err);
            dispatch({ type: DOWNLOADING_PDF_DONE });
            toast.info(i18next.t('createPdf.createPdfFail'), { autoClose: 5000, hideProgressBar: true });
        }
    };
};

export const getImageUrl = (imageId) => {
    return async (dispatch) => {
        try {
            const resp = await Ajax.get('api/survey/image/' + imageId);
            return resp.data;
        } catch (err) {
            return null;
        }
    };
};

export const getVideoUrl = (videoId) => {
    return async (dispatch) => {
        try {
            const resp = await Ajax.get('api/survey/video/' + videoId);
            return resp.data;
        } catch (err) {
            return null;
        }
    };
};

export const saveSurvey = (survey, surveyEnrichment) => {
    return async (dispatch) => {
        try {
            const response = await Ajax.put('api/survey/', {
                Survey: survey,
                EnrichmentBase64: surveyEnrichment,
            });
            if (response.status === 204) {
                toast.info(i18next.t('survey.savingSuccess'), { autoClose: 1500, hideProgressBar: true });
                dispatch({ type: SURVEY_SAVED });
                return true;
            } else {
                return false;
            }
        } catch (err) {
            toast.info(i18next.t('survey.savingFail'), { autoClose: 5000, hideProgressBar: true });
            return false;
        }
    };
};

export const getSurveyEnrichment = (surveyId) => {
    return async (dispatch) => {
        try {
            const resp = await Ajax.get('api/survey/enrichment/' + surveyId);
            return resp.data;
        } catch (err) {
            return null;
        }
    };
};

export const deleteSurvey = async (surveyId) => {
    try {
        await Ajax.delete('api/survey/' + surveyId);
    } catch (err) {
        console.log(err);
    }
};

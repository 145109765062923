import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import LocationEditViewContainer from '../location/locationeditviewcontainer';
import CustomerEditViewContainer from '../customer/customereditviewcontainer';
import LocationPrintView from '../location/locationprintview';
import CustomerView from '../customer/customerview';
import LocationView from '../location/locationview';
import LocationViewEnergy from '../location/locationviewenergy';
import ProductionOrderViewContainer from '../order/productionorderviewcontainer';
import PortfolioView from '../portfolio/portfolioview';
import PortfolioEditView from '../portfolio/portfolioeditview';
import AppRoutesStatic from '../routes/routes';
import GlobalSpinner from '../common/globalspinner';
import MenuBar from './menubar';
import RbacRoute from '../rbac/rbacroute';
import UserEditView from '../admin/usereditview';
import SearchResults from './searchresults';
import NewUserView from '../admin/newuserview';
import InvoiceEditView from '../invoice/invoiceeditview';
import ProductEditView from '../products/producteditview';
import OrderEditViewContainer from '../order/ordereditviewcontainer';
import ProductBundleEditView from '../products/productbundleeditview';
import WebSocketConnector from './websocketconnector';
import LifecycleMonitor from './lifecyclemonitor';
import SurveyEditViewContainer from '../survey/surveyeditviewcontainer';
import SurveyViewContainer from '../survey/surveyviewcontainer';
import { getOpenSurveysForUserPortfolio } from '../modules/surveydata';

const Main = (props) => {
    const dispatch = useDispatch();

    const [surveys, setSurveys] = useState([]);
    const isCustomerSite = process.env.REACT_APP_TARGET === 'customerui';

    const pendingOrders = useSelector((state) => state.orderdata.pendingOrders);
    const pendingCustomerOrders = useSelector((state) => state.customerorderdata.pendingOrders);
    const userProfile = useSelector((state) => state.authentication.userProfile);
    const authenticated = useSelector((state) => state.authentication.authenticated);
    const features = useSelector((state) => state.settingsdata.features);

    const surveysInUse = features != null ? features.surveysInUse : false;

    useEffect(() => {
        async function getSurveys() {
            var resp = await getOpenSurveysForUserPortfolio()(dispatch);
            if (resp && resp.length > 0) {
                setSurveys(resp);
            }
        }
        if (surveysInUse) {
            getSurveys();
        }
    }, [surveysInUse, dispatch]);

    const getContentClassName = () => {
        return props.location && props.location.search === '?sidebar=false' ? 'page-content-full' : 'page-content';
    };

    let shoppingCartContent = isCustomerSite
        ? pendingCustomerOrders != null && pendingCustomerOrders.length > 0
            ? pendingCustomerOrders.length
            : null
        : pendingOrders != null && pendingOrders.length > 0
        ? pendingOrders.length
        : null;
    return (
        <div>
            <GlobalSpinner />
            <WebSocketConnector authenticated={authenticated} />
            <LifecycleMonitor authenticated={authenticated} />
            <MenuBar
                location={props.location}
                shoppingCartContent={shoppingCartContent}
                history={props.history}
                profile={userProfile}
                surveysCount={surveys.length}
            />

            <div className={getContentClassName()}>
                <Route exact path="/" render={() => <Redirect to="/home" />} />

                {AppRoutesStatic.map((route) => (
                    <RbacRoute
                        key={route.Id + 'Route'}
                        user={userProfile}
                        path={route.Path}
                        component={route.Component}
                    />
                ))}
                {!isCustomerSite && (
                    <div>
                        <RbacRoute
                            key="editLocationRoute"
                            user={userProfile}
                            path="/locations/edit/:locationId"
                            component={LocationEditViewContainer}
                        />
                        <RbacRoute
                            key="editCustomerRoute"
                            user={userProfile}
                            path="/customers/edit/:customerId"
                            component={CustomerEditViewContainer}
                        />
                        <RbacRoute
                            key="printLocationRoute"
                            user={userProfile}
                            path="/locations/print/:locationId"
                            component={LocationPrintView}
                        />
                        <RbacRoute
                            key="customerViewRoute"
                            user={userProfile}
                            path="/customers/:customerId"
                            render={(props) => <CustomerView {...props} history={props.history} />}
                        />
                        <RbacRoute
                            key="locationViewRoute"
                            user={userProfile}
                            path="/locations/:locationId"
                            component={LocationView}
                        />
                        <RbacRoute
                            key="locationViewEnergyRoute"
                            user={userProfile}
                            path="/locations/:locationId/energy"
                            component={LocationViewEnergy}
                        />
                        <RbacRoute
                            key="productionOrderViewRoute"
                            user={userProfile}
                            path="/ordernumber/:orderNumber"
                            component={ProductionOrderViewContainer}
                        />
                        <RbacRoute
                            key="portfolioViewRoute"
                            user={userProfile}
                            path="/admin/portfolio/:portfolioId"
                            component={PortfolioView}
                        />
                        <RbacRoute
                            key="portfolioEditRoute"
                            user={userProfile}
                            path="/admin/portfolio/edit/:portfolioId"
                            component={PortfolioEditView}
                        />
                        <RbacRoute
                            key="userEditRoute"
                            user={userProfile}
                            path="/admin/user/edit/:userId"
                            component={UserEditView}
                        />
                        <RbacRoute
                            key="productEditRoute"
                            user={userProfile}
                            path="/product/edit/:productId"
                            component={ProductEditView}
                        />
                        <RbacRoute
                            key="searchResultsRoute"
                            user={userProfile}
                            path="/search/:term"
                            render={(props) => <SearchResults {...props} history={props.history} />}
                        />
                        <RbacRoute
                            key="newUserRoute"
                            user={userProfile}
                            path="/admin/user/new"
                            component={NewUserView}
                        />
                        <RbacRoute
                            key="invoiceEditRoute"
                            user={userProfile}
                            path="/invoices/edit/:invoiceid"
                            component={InvoiceEditView}
                        />
                        <RbacRoute
                            key="orderEditRoute"
                            user={userProfile}
                            path="/ordernumber/edit/:orderNumber"
                            component={OrderEditViewContainer}
                        />
                        <RbacRoute
                            key="productBundleEditRoute"
                            user={userProfile}
                            path="/product/bundleedit/:bundleId"
                            component={ProductBundleEditView}
                        />
                        <RbacRoute
                            key="surveyEditRoute"
                            user={userProfile}
                            path="/survey/edit/:surveyId"
                            component={SurveyEditViewContainer}
                        />
                        <RbacRoute
                            key="surveyViewRoute"
                            user={userProfile}
                            path="/survey/:surveyId"
                            component={SurveyViewContainer}
                        />
                    </div>
                )}
                {isCustomerSite && (
                    <div>
                        <RbacRoute
                            key="locationViewRoute"
                            user={userProfile}
                            path="/locations/:locationId"
                            component={LocationView}
                        />
                        <RbacRoute
                            key="productionOrderViewRoute"
                            user={userProfile}
                            path="/ordernumber/:orderNumber"
                            component={ProductionOrderViewContainer}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Main;

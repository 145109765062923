import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

const useStyles = makeStyles((theme) => ({
    videoPlaceholder: (props) => ({
        position: 'relative',
        width: props.width || 250,
        height: props.height || 150,
        backgroundColor: theme.palette.grey[300],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
    }),
    playButton: {
        position: 'absolute',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.grey[700],
        borderRadius: '50%',
        width: 50,
        height: 50,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.grey[800],
        },
    },
}));

export default function VideoPlaceholder({ width, height, onClick }) {
    const classes = useStyles({ width, height });

    return (
        <div className={classes.videoPlaceholder} onClick={onClick}>
            <div data-testid="play-button" className={classes.playButton}>
                <PlayArrowIcon fontSize="large" />
            </div>
        </div>
    );
}

VideoPlaceholder.propTypes = {
    width: PropTypes.number, // Width of the video placeholder (optional)
    height: PropTypes.number, // Height of the video placeholder (optional)
    onClick: PropTypes.func.isRequired, // Function to handle click events (required)
};

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    thumbDefault: {
        width: '300px',
    },
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    zoomedImage: {
        width: '80%',
        maxHeight: '90vh',
        objectFit: 'contain',
    },
}));

export default function ZoomableImage(props) {
    const { thumbUrl, fullResUrl, thumbClass } = props;
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div>
            <img
                src={thumbUrl}
                alt="Thumbnail"
                className={thumbClass || classes.thumbDefault}
                style={{ cursor: 'pointer' }}
                onClick={() => setIsOpen(true)}
            />

            {isOpen && (
                <div className={classes.overlay} onClick={() => setIsOpen(false)}>
                    <img src={fullResUrl} alt="Zoomed" className={classes.zoomedImage} />
                </div>
            )}
        </div>
    );
}

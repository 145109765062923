import React from 'react';
import PropTypes from 'prop-types';
import { Box, useMediaQuery } from '@material-ui/core';
import { ImageList, ImageListItem, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@mui/material/styles';
import VideoPlaceholder from './videoplaceholder';

const useStyles = makeStyles((theme) => ({
    imageList: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))', // Responsive grid
        gap: theme.spacing(2), // Use theme spacing for consistent gaps
        gridAutoFlow: 'row',
    },
    imageListItem: {
        width: '100%',
    },
}));

export default function VideoList({ videos, onVideoPlay }) {
    const theme = useTheme();
    const classes = useStyles();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <ImageList className={classes.imageList} cols={isSmallScreen ? 1 : undefined}>
            {videos.map((video) => (
                <ImageListItem key={video.videoId} className={classes.imageListItem}>
                    <VideoPlaceholder onClick={() => onVideoPlay(video.videoId)} />
                    <Box sx={{ p: 1 }}>
                        {video.comments && (
                            <Typography variant="body2" color="textSecondary">
                                {video.comments}
                            </Typography>
                        )}
                    </Box>
                </ImageListItem>
            ))}
        </ImageList>
    );
}

VideoList.propTypes = {
    videos: PropTypes.arrayOf(
        PropTypes.shape({
            videoId: PropTypes.string.isRequired,
            comments: PropTypes.string,
        })
    ).isRequired,
    onVideoPlay: PropTypes.func.isRequired,
};

import React, { useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const waitTimeSecs = 2;

export default function ConfirmationDialog(props) {
    const open = props.open;
    const useDelay = props.useDelay || false;
    const confirm = props.confirm;
    const cancel = props.cancel;
    const confirmText = !!props.confirmText ? props.confirmText : 'Ok';
    const showCancel = !props.hideCancel;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();
    const [enabled, setEnable] = React.useState(!useDelay);
    const intervalid = useRef(null);
    const [count, setCount] = React.useState(waitTimeSecs);

    useEffect(() => {
        if (useDelay && open && !intervalid.current) {
            intervalid.current = setInterval(() => {
                setCount((prev) => {
                    if (prev > 1) return prev - 1;
                    clearInterval(intervalid.current);
                    intervalid.current = null;
                    setEnable(true);
                    return 0;
                });
            }, 1000);
        } else if (useDelay && !open) {
            if (intervalid.current) {
                clearInterval(intervalid.current);
                intervalid.current = null;
            }
            setCount(waitTimeSecs);
            setEnable(false);
        }
        return () => clearInterval(intervalid.current);
    }, [open, useDelay]);

    if (open)
        return (
            <Dialog
                onClose={cancel}
                aria-labelledby="simple-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={'md'}
                fullScreen={fullScreen}>
                <DialogContent dividers>{props.children}</DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        name="btn-modal-confirm"
                        onClick={confirm}
                        data-testid="btn-modal-confirm"
                        disabled={!enabled}>
                        {confirmText} {!enabled ? `(${count})` : ''}
                    </Button>
                    &nbsp;&nbsp;
                    {showCancel && (
                        <Button variant="contained" color="default" name="btn-modal-cancel" onClick={cancel}>
                            {t('buttons.cancel')}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        );
    return null;
}

ConfirmationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    confirm: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    confirmText: PropTypes.string,
    hideCancel: PropTypes.bool,
};
